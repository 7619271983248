<template>
  <section class="about_we">
    <div class="animate  about_cont" ref="aboutCont">
      <!-- 是专业从事应用系统开发的高新技术企业，以技术研发为主的技术型科技公司。 -->
      <title-txt :txtObj="txtObj"></title-txt>
      <p class="txt">河南金蝉子软件科技有限公司是一家  " 以技术发展为核心，以应用创新为导向 "  的互联网软件科技有限公司。 <br/>我们有最牛的团队、最优的产品和最好的服务，旨在帮助客户实现数字化转型和业务升级。
        <!-- <br/> 我们是一家以技术为核心的软件技术供应商，力求在满足客户需求的情况下，把技术框架搭建做到完美，我们会毫无保留的将我们的运营经验、案例、推广方式提供给客户以供参考。 -->
      </p>
      <more-btn btnTxt="查看详情" ></more-btn>
    </div>
  </section>
</template>

<script>
import TitleTxt from '@/components/TitleTxt'
import MoreBtn from '@/components/MoreBtn'
import animateVisible from '@/mixin/animate-visible'
export default {
  name: 'AboutWe',
  components: { TitleTxt, MoreBtn },
  mixins: [animateVisible],
  data () {
    return {
      txtObj: { title: '关于我们', englishTxt: 'ABOUT US' }
    }
  },
  mounted () {
    this.animateVisible(this.$refs.aboutCont, 'animate_up')
  },
  methods:{
    // btnTxt(){
    //   this.$router.push({
    //         path:"/about-we",
    //     })
    // }
  }
}
</script>

<style lang="scss" scoped>
.about_we {
  padding: 60px 0;
  background-color: #18191b;
  .about_cont {
    // width: 1200px;
    margin: 0 auto;
    text-align: center;
    // animation-name: fadeInUp;

    .txt {
      color: #ddd;
      // font-size: 14px;
      font-size: 16px;
      line-height: 1.8;
      margin-bottom: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
@media screen and (max-width: 1200px) {
  .about_we {
    padding: 30px 0;
    .about_cont {
      width: 90%;
    }
  }
}
</style>

<template>
  <!-- 底部 -->
  <footer class="foot_box">
    <section class="list_box">
      <!-- <div style="margin-left: 18%;font-size: 20px;color: #FFFFFF;line-height: 18px; ">联系我们</div> -->
      <div class="list_cont">
        <div class="col">
          <h4>联系我们</h4>
          <ul class="child_list">
            <li>公司名称：河南金蝉子软件科技有限公司</li>
            <li>联系部门：金蝉子产品运营部</li>
          </ul>
        </div>
        <div class="col">
          <h4 style="opacity: 0;">服务项目</h4>
          <ul class="child_list">
            <li>固定电话：0371-63696967</li>
            <li>联系电话：18339991428(马经理)</li>
          </ul>
        </div>
        <div class="col">
          <h4 style="opacity: 0;">案例展示</h4>
          <ul class="child_list">
            <li>企业邮箱：vip_jinchanzi@163.com</li>
            <li>公司地址：河南自贸试验区郑州片区（郑东）榆林北路36号绿地中心南塔1号楼46层4611号</li>
          </ul>
        </div>
        
        
        <!-- <div class="col">
          <h4>新闻资讯</h4>
          <ul class="child_list">
            <li>公司简介</li>
            <li>企业文化</li>
            <li>发展历程</li>
          </ul>
        </div>
        <div class="col">
          <h4>联系</h4>
          <ul class="child_list">
            <li>公司简介</li>
            <li>企业文化</li>
            <li>发展历程</li>
          </ul>
        </div> -->
      </div>

    </section>
    <section class="des_box">
      <div class="des_cont" @click="Jump()">Copyright © 河南金蝉子软件&nbsp;豫ICP备16037967号-5</div>
    </section>
  </footer>
</template>

<script>
export default {
  name: 'FootBox',
  data() {
    return {}
  },
  methods:{
    Jump() {
      window.location.href = "https://beian.miit.gov.cn/"
    }
  }
}
</script>

<style lang="scss" scoped>
.foot_box {
  .list_box {
    width: 100%;
    background-color: #111;
    color: #93959b;
    font-size: 13px;
    padding-top: 40px;
    padding-bottom: 35px;

    .list_cont {

      width: 1200px;
      margin: 0 auto;
      margin-top: 1%;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .col {
        font-size: 14px;
        font-family: Helvetica;
        // color: #FFFFFF;
        line-height: 17px;
        padding: 0 5px;

        >h4 {
          line-height: 20px;
          font-size: 16px;
          // color: white;
          color: #ddd;
          margin-bottom: 10px;
        }

        .child_list {
        
          li {
            line-height: 2;
          }
        }
      }
    }
  }

  .des_box {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    background: #0d0d0d;
    font-size: 13px;
    color: #93959b;

    .des_cont {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
          &:hover{
            color: white;
          }
    }
  }

  @media screen and (max-width: 1200px) {
    .list_box {
      .list_cont {
        width: 100%;
        padding: 0 15px;
      }
    }

    .des_box {
      .des_cont {
        
        width: 100%;
        padding: 0 15px;
      }
    }
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import FurnitreIndex from '@/views/furniture-index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: FurnitreIndex,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/child-page',
    component: () => import('@/views/children-page'),
    redirect: '/about-we',
    children: [
      {
        path: '/about-we',
        name: 'about-we',
        component: () => import('@/views/children-page/about-we'),
        meta: {
          title: '关于我们'
        }
      },
      {
        path: '/serve-project',
        name: 'serve-project',
        component: () => import('@/views/children-page/serve-project'),
        meta: {
          title: '服务项目'
        }
      },
      {
        path: '/case-show',
        name: 'case-show',
        component: () => import('@/views/children-page/case-show'),
        meta: {
          title: '案例展示'
        }
      },
      {
        path: '/new-box',
        name: 'new-box',
        component: () => import('@/views/children-page/new-box'),
        meta: {
          title: '新闻咨询'
        }
      },
      {
        path: '/expert-list',
        name: 'expert-list',
        component: () => import('@/views/children-page/expert-list'),
        meta: {
          title: '专家团队'
        }
      },
      {
        path: '/talents-box',
        name: 'talents-box',
        component: () => import('@/views/children-page/talents-box'),
        meta: {
          title: '人力资源'
        }
      },
      {
        path: '/contact-box',
        name: 'contact-box',
        component: () => import('@/views/children-page/contact-box'),
        meta: {
          title: '联系我们'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes

})

export default router

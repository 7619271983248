<template>
  <section class="serve_flow">
    <div class="animate  flow_cont" ref="flowCont">
      <title-txt :txtObj="txtObj"></title-txt>
      <ul class="flow_tabs">
        <li v-for="(item, index) in flowList" :key="index" :class="flowIndex === index ? 'active_li' : ''"
          @click="flowHandler(index)">
          <p>{{ item.title }}</p>
          <p>{{ item.titleEnglish }}</p>
        </li>
      </ul>
      <ul class="tab_item">
        <li v-for="(item, index) in flowList" :key="index" v-show="flowIndex === index">
          <p v-for="(itemTxt, idnexTxt) in item.txtArr" :key="idnexTxt">{{ itemTxt }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import TitleTxt from '@/components/TitleTxt'
import animateVisible from '@/mixin/animate-visible'
export default {
  name: 'ServeFlow',
  components: { TitleTxt },
  mixins: [animateVisible],
  data() {
    return {
      txtObj: {
        title: '服务流程',
        englishTxt: 'SERVICES PROCESS'
      },
      flowIndex: 0,
      flowList: [
        {
          title: '需求沟通',
          titleEnglish: 'Communication',
          txtArr: [
            '定制开发意向客户可通过在线咨询、电话咨询、亦可直接在设计团队预约满意的设计师，客服人员将第一时间响应您的请求，竭诚为您服务。',
            '设计师仔细聆听客户的需求，并解答分析,初步方案确认后出具预算。'
          ]
        },
        {
          title: '方案确认',
          titleEnglish: 'Scheme confirmation',
          txtArr: [
          '“好的公司聊项目”——软件开发并不是有统一规格的商品，靠谱的公司在报价前都会详细的询问客户项目的背景，业务逻辑，商业逻辑，以确保准确的勾勒出用户想要的软件雏形作为准确报价的依据。'
          ]
        },
        {
          title: '签约立项',
          titleEnglish: 'Optimization Design',
          txtArr: [
          '如果有幸能为您服务，我们会跟您确定研发的阶段、工期和价格并签订正式合同，合作正式开始。',
          ]
        },
        {
          title: 'UI设计',
          titleEnglish: 'UI design',
          txtArr: [
            'UI设计是指对软件的人机交互、操作逻辑、界面美观的整体设计。我们的设计师会根据要求制作符合软件主题的设计稿，这一步完成后，您就可以看到有颜色的软件设计图啦。',
          ]
        },
        {
          title: '程序开发',
          titleEnglish: 'Program development',
          txtArr: [
            '程序员拿到设计图后会使用开发工具所支持的语言写出源文件，并且在制作时会遵循通用性、可扩充性、稳定性等原则以确保代码质量。',
          ]
        },
        {
          title: '产品测试',
          titleEnglish: 'Product Test',
          txtArr: [
            '为了执行“一次就把事做对”的质量原则，在交付项目之前，我们的测试工程师会使用很多暴力测试来确保交付到客户手中的软件在任何情况下都保持优异的表现。'
          ]
        }, {
          title: '上线交付',
          titleEnglish: 'Online delivery',
          txtArr: [
            '软件上线之前（通常会提前一个月），我们还会协助您申请服务器、软件著作权、支付账号等必要的资质，确保上线过程顺畅。',
          ]
        }, {
          title: '系统维护',
          titleEnglish: 'System maintenance',
          txtArr: [
            '我们承诺：所有我们制作的软件皆为终生免费维护，这一条会写在与您签订的合同内，以视为我们对自己开发软件的质量有相当的信心。',
          ]
        }
      ]
    }
  },
  mounted() {
    this.animateVisible(this.$refs.flowCont, 'animate_up')
  },
  methods: {
    flowHandler(index) {
      this.flowIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.serve_flow {
  background-image: url('@/assets/images/serve-flow.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 60px 0 60px;

  .flow_cont {
    width: 1500px;
    margin: 0 auto;

    .flow_tabs {
      display: flex;
      justify-content: space-around;
      padding-bottom: 50px;
      border-bottom: 1px solid #ccc;
      flex-wrap: wrap;

      >li {
        width: 150px;
        height: 150px;
        border: 2px solid #c89302;
        border-radius: 50%;
        background-color: #c89302;
        color: #222;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.5s ease 0s;
        position: relative;

        &:hover {
          background-color: #fab702;
          border-color: #fab702;
          color: #222;
        }

        p:nth-child(1) {
          font-size: 18px;
          line-height: 1.3;
        }

        p:nth-child(2) {
          font-size: 12px;
          line-height: 2;
        }
      }

      .active_li {
        background-color: transparent;
        border-color: #fff;
        color: #fff;

        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 50px;
          background-color: #ccc;
          position: absolute;
          left: 50%;
          bottom: -52px;
        }

        &:hover {
          background-color: transparent;
          border-color: #666;
          color: white;
        }
      }
    }

    .tab_item {
      >li {
        color: #ddd;
        font-size: 14px;
        line-height: 1.8;

        p {
          margin-top: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .serve_flow {
    padding: 30px 10px;

    .flow_cont {
      width: 100%;

      .flow_tabs {
        >li {
          width: 136px;
          height: 136px;
        }
      }
    }
  }
}

@media screen and (max-width: 970px) {
  .serve_flow {
    .flow_cont {
      .flow_tabs {
        >li {
          width: 94px;
          height: 94px;
          margin-top: 10px;

          p:nth-child(1) {
            font-size: 14px;
          }

          p:nth-child(2) {
            display: none;
          }
        }

        .active_li {
          &::after {
            width: 0;
          }
        }
      }
    }
  }
}</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/plugins/element-ui'
// import 'animate.css' //自己写了动画就不引入了


Vue.config.strict = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.resSetItem = (key, newVal) => {
  if (key === 'playFlagState') { 
  //监听本地存储 key为 playFlagState时 创建新的监听事件
      var newStorageEvent = document.createEvent('StorageEvent')
        const storage = {
            setItem: function (k, val) {
              //执行会话存储
              sessionStorage.setItem(k, val)
              newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
              window.dispatchEvent(newStorageEvent)
            }
        }
      return storage.setItem(key, newVal)
  }

}

<template>
  <section class="new_box">
    <div class="animate new_cont" ref="news">
      <TitleTxt :txtObj="txtObj"></TitleTxt>


      <el-row :gutter="15">
        <el-col :xs="24" :sm="6">
          <el-row :gutter="20" class="item_row">
            <el-col :xs="24" :sm="24">
              <div class="day">
                <!-- <div class="image">  07          </div> -->
                <img src="../../../assets/images/Enterprise/img (4).png" alt="">


              </div>
              <!-- <div class="year_month">2023-04</div> -->
            </el-col>
            <el-col :xs="24" :sm="24">
              <!-- <h4>您的内部指南：什么是竞争环境分析？</h4> -->
              <div class="layout">
                <div class="h_four">
                  专属客服
                </div>
                <p class="txt">专人为您全程服务，包含后期的维护， <br />与二次开发，降低沟通成本。</p>
              </div>
            </el-col>
          </el-row>
        </el-col>

        <el-col :xs="24" :sm="6">
          <el-row :gutter="20" class="item_row">
            <el-col :xs="24" :sm="24">
              <div class="day">
                <!-- <div class="image">  07          </div> -->
                <img src="../../../assets/images/Enterprise/img (2).png" alt="">


              </div>
              <!-- <div class="year_month">2023-04</div> -->
            </el-col>
            <el-col :xs="24" :sm="24">
              <!-- <h4>您的内部指南：什么是竞争环境分析？</h4> -->
              <div class="layout">
                <div class="h_four">
                  定制开发
                </div>
                <p class="txt">支持个性化功能，   <br />符合发展趋势，超越竞争对手。</p>
              </div>
            </el-col>
          </el-row>
        </el-col><el-col :xs="24" :sm="6">
          <el-row :gutter="20" class="item_row">
            <el-col :xs="24" :sm="24">
              <div class="day">
                <!-- <div class="image">  07          </div> -->
                <img src="../../../assets/images/Enterprise/img (3).png" alt="">


              </div>
              <!-- <div class="year_month">2023-04</div> -->
            </el-col>
            <el-col :xs="24" :sm="24">
              <!-- <h4>您的内部指南：什么是竞争环境分析？</h4> -->
              <div class="layout">
                <div class="h_four">
                  系统维护
                </div>
                <p class="txt">队伍流程化管理，多种预防方案及时解决方案， <br />24小时为您保驾护航。</p>
              </div>
            </el-col>
          </el-row>
        </el-col><el-col :xs="24" :sm="6">
          <el-row :gutter="20" class="item_row">
            <el-col :xs="24" :sm="24">
              <div class="day">
                <!-- <div class="image">  07          </div> -->
                <img src="../../../assets/images/Enterprise/img (1).png" alt="">


              </div>
              <!-- <div class="year_month">2023-04</div> -->
            </el-col>
            <el-col :xs="24" :sm="24">
              <!-- <h4>您的内部指南：什么是竞争环境分析？</h4> -->
              <div class="layout">
                <div class="h_four">
                  二次开发
                </div>
                <p class="txt"> 远程实施准备+实地实施测试， <br />为您带来极致的体验。</p>
              </div>
            </el-col>
          </el-row>
        </el-col>



        
        <!-- <el-col :xs="24" :sm="12">
          <el-row :gutter="20" class="item_row">
            <el-col :xs="4" :sm="4">
              <div class="day">07</div>
              <div class="year_month">2023-04</div>
            </el-col>
            <el-col :xs="20" :sm="20">
              <h4>企业创建官网的好处！</h4>
              <p class="txt">树立企业形象，提升可信度和专业度，定制内容营销方案，提升营销推广效率加快实现营销目标。提供线上服务，及时满足客户需求。增加企业曝光率，提升行业竞争力，低投入，高回报，可持续···</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-row :gutter="20" class="item_row">
            <el-col :xs="4" :sm="4">
              <div class="day">07</div>
              <div class="year_month">2023-04</div>
            </el-col>
            <el-col :xs="20" :sm="20">
              <h4>企业拥有定制app的作用？</h4>
              <p class="txt">提高用户体验，拓展市场渠道。提高品牌知名度。提高工作效率，提供更多的便捷服务······</p>
            </el-col>
          </el-row>
        </el-col> -->
      </el-row>
    </div>
  </section>
</template>

<script>
import TitleTxt from '@/components/TitleTxt'
import animateVisible from '@/mixin/animate-visible'
export default {
  name: 'NewBox',
  components: { TitleTxt },
  mixins: [animateVisible],
  data() {
    return {
      txtObj: {
        title: '企业优势',
        englishTxt: 'Enterprise advantages'
      }
    }
  },
  mounted() {
    this.animateVisible(this.$refs.news, 'animate_up')
  }
}
</script>

<style lang="scss" scoped>
.new_box {
  padding: 60px 0;
  background: #18191b;

  .new_cont {
    width: 1500px;
    margin: 0 auto;

    .item_row {
      margin-bottom: 30px;
      text-align: center;

      .day {
        // width: 30%;
        // margin: 0 auto;
        // line-height: 50px;
        // border-bottom: 1px solid #18191b;
        color: #999;
        font-size: 30px;
        text-align: center;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;

        img {
          width: 88px;
          height: 88px;
          background: #222326;
          border-radius: 50%;
        }
      }

      // .year_month {
      //   text-align: center;
      //   background: #222326;
      //   line-height: 22px;
      //   font-size: 12px;
      // }
      .layout {
        // background-color: #666;
        border: 1px solid #666;
        border-radius: 10px;
        padding: 12% 0;
        margin-top: -40px;
        transition: all 1s;
        &:hover {
          background-color: rgba(0, 0, 0, 0.8) !important;
          transition: all 1s;
          transform: scale(1.05);
        }
      }

      // border: 1px solid #666;
      // color: #808080;

      .h_four,
      h4 {
        margin: 0 auto;
        width: 20%;
        color: #ddd;
        font-size: 16px;
        line-height: 2.4;
        margin-bottom: 15px;
        border-bottom: 1px solid #fab702;

        &:hover {
          color: #fab702;
        }
      }

      .txt {
        color: #808080;
        line-height: 1.6;
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
      cursor: pointer;

      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .new_box {
    padding: 30px 0;

    .new_cont {
      width: 100%;
      padding: 0 15px;
    }
  }
}
</style>
